@import '../theme-bootstrap';

.elc-responsive-modal-mask {
  .elc-auto-replenish-modal {
    display: block;
    text-align: $ldirection;
    font-weight: normal;
    &-close-button-icon {
      background: none;
      color: $color-black;
      width: auto;
      height: auto;
      margin: 0;
      padding: 0;
      text-transform: none;
      &:hover {
        background: none;
      }
      & > span {
        display: flex;
        justify-content: flex-end;
      }
      .elc-remove-icon {
        margin-bottom: 5px;
        @media #{$cr19-large-up} {
          margin: 0;
        }
      }
    }
    .elc-basic-tout__content-over-media-block {
      background: $color-white;
    }
    p {
      text-transform: none;
      font-weight: normal;
    }
    ul {
      list-style: disc;
      list-style-position: inside;
      padding-#{$ldirection}: 40px;
    }
    li {
      line-height: 1.5;
      text-transform: none;
      text-indent: -24px;
      @media #{$cr19-large-up} {
        text-indent: -26px;
      }
    }
  }
}
